<script lang="tsx">
import { defineComponentCoreUiBreadcrumbs } from '@core/app/components/core/ui/CoreUiBreadcrumbs.vue'
import { IconChevron, IconHouse } from '#components'

export default defineComponentCoreUiBreadcrumbs({
    props: {
        includeRoot: {
            default: true,
        },
        breadcrumbClass: {
            default: 'sim-menu-btn',
        },
    },
    slots: {
        separator: () => <IconChevron size="xs" rotate={-90} />,
        breadcrumb: ({ isRoot, item }) => <>{isRoot ? <IconHouse size="sm" class="text-primary-900" aria-label={item ? item.label : item} /> : 'label' in item ? item.label : item}</>,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiBreadcrumbs" as *;

@include container {
    flex-wrap: wrap;
}

@include item {
    color: $sim-c-basic-700;

    @include sim-text-small;
    @include sim-font-regular
}

@include item--active {
    color: $sim-c-primary-900;

    @include sim-font-medium;
}

@include separator {
    color: $sim-c-basic-600;
}

@include collapsed {
    color: $sim-c-primary-800;
}

</style>
